export default defineNuxtRouteMiddleware(async (to, _from) => {
  const { fetchUser, setUser } = useDirectusAuth();
  const { refreshTokens, checkAutoRefresh } = useDirectusToken();

  await checkAutoRefresh()

  const user = useDirectusUser();
  if (!user.value) {
    try {
      const user = await fetchUser();
      setUser(user.value);
    } catch (e) {
      // console.log("CEST LES RATS CEST LES RATS ON EST LA POUR CA", e);
    }
  }

  // console.log("user", user.value);
  // stay on the same apge
  if (!user.value?.role) {
    return navigateTo("/login");
  }
});
